<template>
  <v-dialog :value="value" @input="(value) => $emit('change', value)" width="500" persistent>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
          <v-card-title class="text-h5"> Alterando Franquia </v-card-title>
          <v-card-text>
            <e-label>Selecione uma franquia aqui</e-label>
            <ValidationProvider name="Franquia" rules="required" v-slot="{ errors }">
              <e-autocomplete
                :error-messages="errors"
                :items="franquias.filter((item) => !item.deleted_at)"
                :item-text="(item) => item.descricao"
                return-object
                v-model="franquiaSelecionada"
              />
            </ValidationProvider>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" type="button" small @click="() => $emit('input', false)">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="submittingForm"
              :loading="submittingForm"
              small
            >
              Atualizar
            </v-btn>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("Auth", ["senhaFraca", "user"]),
    ...mapGetters("Franquias", ["franquiasList"]),
  },
  data() {
    return {
      franquiaSelecionada: null,
      submittingForm: false,
      franquias: [],
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      await this.$services.franquiasService.syncAll();
      const data1 = this.franquiasList?.map((franq) => {
        if (!this.user.franquias_permitidas) {
          this.franquias = [...this.franquiasList];
        }
        const data2 = this.user.franquias_permitidas?.map((franquiaPermitida) => {
          if (franquiaPermitida === franq.id) {
            this.franquias.push(franq);
          }
        });
      });
    },
    ...mapActions("Franquias", ["loadFranquias"]),
    async submitForm() {
      this.submittingForm = true;
      try {
        await this.$services.authService.mudarDeFranquia(this.franquiaSelecionada);
        this.$toast.success("Franquia atualizada com sucesso!");
        this.$emit("input", false);
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
  },
};
</script>

<style></style>
