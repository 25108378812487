<template>
  <div :style="myStyles">
    <LineChartGenerator
      ref="lineChart"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="myStyles"
      :width="width"
      :height="height"
    />
    <v-row>
      <v-col class="mt-3 mb-3">
        <v-btn color="primary" class="mr-2" @click="downloadChart('png')">
          Baixar imagem
          <v-icon right>mdi-file-image</v-icon>
        </v-btn>
        <v-btn @click="downloadChart('pdf')">
          Baixar PDF
          <v-icon right>mdi-file-pdf</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  computed: {
    myStyles() {
      return {
        height: `${this.height}px`,
        position: "relative",
      };
    },
  },
  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 500,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.datasets,
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    downloadChart(format) {
      const canvas = this.$refs.lineChart.$el.querySelector("canvas");

      if (format === "png") {
        const tempCanvas = document.createElement("canvas");
        const tempCtx = tempCanvas.getContext("2d");
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        tempCtx.fillStyle = "#f0f0f0";
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
        tempCtx.drawImage(canvas, 0, 0);
        const dataURL = tempCanvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "chart.png";
        link.click();
      } else if (format === "pdf") {
        html2canvas(canvas).then((canvas) => {
          const tempCanvas = document.createElement("canvas");
          const tempCtx = tempCanvas.getContext("2d");
          tempCanvas.width = canvas.width;
          tempCanvas.height = canvas.height;

          tempCtx.fillStyle = "#f0f0f0";
          tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

          tempCtx.drawImage(canvas, 0, 0);

          const imgData = tempCanvas.toDataURL("image/png");
          const pdf = new jsPDF("l", "mm", "a4");
          const pdfWidth = pdf.internal.pageSize.width;
          const pdfHeight = pdf.internal.pageSize.height;
          const imgWidth = 150;
          const imgHeight = 100;

          // Centralizando a imagem na página
          const xOffset = (pdfWidth - imgWidth) / 2;
          const yOffset = (pdfHeight - imgHeight) / 2;

          pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);
          pdf.save("chart.pdf");
        });
      }
    },
  },
  watch: {
    datasets(value) {
      this.chartData.datasets = value;
    },
    labels(value) {
      this.chartData.labels = value;
    },
  },
};
</script>

<style scoped>
/* Adicione estilos personalizados aqui */
</style>
