<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => this.$router.go(-1)"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Diário Atendimento
        </h1>
      </v-col>
    </v-row>
    <v-col>
      <v-card>
        <v-card-title>DIÁRIO DE DESENVOLVIMENTO</v-card-title>
        <v-card-text>
          <h4>Turma: {{ form.gestao ? form.gestao.turma.descricao : "- - -" }}</h4>
          <h4>Aluno: {{ form.gestao ? form.gestao.matricula.aluno.nomecompleto : "- - -" }}</h4>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-title>Professores (diários)</v-card-title>
        <v-card-text>
          <div>
            Professor(a): {{ form.gestao ? form.gestao.professor.nome : "- - -" }} | Tipo de Ensino:
            {{ form.gestao ? form.gestao.disciplinas_vinculo[0].descricao : "" }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-col>
          <v-row>
            <v-col class="pt-0 pb-0" cols="12" sm="4">
              <e-label>Selecione uma data</e-label>
              <!-- <v-text-field :error-messages="errors" v-model="form.data" dense solo /> -->
              <e-date-picker v-model="form.data" placeholder="dd/mm/yyyy"></e-date-picker>
            </v-col>
            <v-col class="pb-0" cols="12" sm="4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Horário de início da aula"
                rules="required"
              >
                <v-text-field
                  v-model="aulaSelecionada.horario_inicio"
                  label="Horário de inicio"
                  prepend-icon="mdi-clock-time-four-outline"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col class="pb-0" cols="12" sm="4">
              <ValidationProvider
                v-slot="{ errors }"
                name="Horário de fim da aula"
                rules="required"
              >
                <v-text-field
                  v-model="aulaSelecionada.horario_fim"
                  label="Horário de Fim"
                  prepend-icon="mdi-clock-time-four-outline"
                  :error-messages="errors"
                  v-mask="'##:##'"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <v-row v-for="(item, index) in $constants.fichaEducacaoEspecialDiario" :key="index">
            <v-col cols="12">
              <e-label>{{ item.descricao }}</e-label>
              <v-textarea solo dense v-model="form[item.form]" />
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-row>
              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
            </v-row>
          </v-col>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  data() {
    return {
      form: {
        data: null,
        horario_inicio: null,
        horario_fim: null,
      },
      modal: false,
      menu: false,
      horarios: [],
      matricula: null,
      gestao_de_aulas: [],
      submittingForm: false,
      aulaSelecionada: {
        horario_inicio: null,
        horario_fim: null,
      },
    };
  },
  methods: {
    async createOrUpdate() {
      this.submittingForm = true;
      this.$loaderService.open("Carregando dados...");
      try {
        // this.form.data = this.form.data;
        const ficha_id = this.$route.params;
        this.form.ficha_id = ficha_id;
        this.form.horario_inicio = this.aulaSelecionada.horario_inicio;
        this.form.horario_fim = this.aulaSelecionada.horario_fim;
        this.$toast.info("Diário editado com sucesso!");
        await this.$services.educacaoInclusivaService.lancarFichaDeDiarioAtendimentoEdit(this.form);
      } catch (error) {
        this.$handleError(error);
      }
      this.$router.push({ name: "gestoesDeAulasAuxiliares" });
      this.submittingForm = false;
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const ficha_id = this.$route.params;
        const response =
          await this.$services.educacaoInclusivaService.carregarFichaDeDiarioAtendimentoEdit(
            parseInt(ficha_id.id, 10)
          );
        console.log(JSON.stringify(this.response, null, 2));
        this.form = response.data.ficha ? response.data.ficha : {};
        this.lancarRelatorio = this.$constants.fichaEducacaoEspecialDiario;
        this.data = response?.data;
        this.horarios = response.data.horarios;
        this.aulaSelecionada = {
          horario_inicio: response.data.ficha.horario_inicio || "",
          horario_fim: response.data.ficha.horario_fim || "",
        };
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
