<template>
  <div>
    <div class="mb-5">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="criarEtapa">
        Cadastrar Nova Etapa
      </v-btn>
    </div>
    <p v-if="etapasByCircuito.length < 1" class="mt-5">
      Não existe nenhuma etapa cadastrada nesse circuito de notas!
    </p>
    <div v-else>
      <v-expansion-panels focusable>
        <v-expansion-panel
          active-class="yellow lighten-5"
          v-for="(item, i) in etapasByCircuito"
          :key="i"
        >
          <v-expansion-panel-header class="titleBimestre py-0" v-slot="{ open }">
            <div class="d-flex justify-space-between align-center">
              <div v-text="item.descricao" class="text-caption font-weight-medium"></div>
              <div>
                <v-fade-transition leave-absolute>
                  <v-row v-if="!open" no-gutters style="width: 100%">
                    <v-btn-toggle dense multiple>
                      <v-btn
                        x-small
                        color="blue-grey lighten-3"
                        @click="() => editarEtapa(item.id)"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon x-small v-bind="attrs" v-on="on">fa fa-edit </v-icon>
                          </template>
                          <span>Editar {{ item.descricao }}</span>
                        </v-tooltip>
                      </v-btn>
                      <v-btn x-small color="error" v-on="on" @click="() => deletarEtapa(item)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon x-small v-bind="attrs" v-on="on">fa fa-trash </v-icon>
                          </template>
                          <span>Excluir {{ item.descricao }}</span>
                        </v-tooltip>
                      </v-btn>
                    </v-btn-toggle>
                  </v-row>
                </v-fade-transition>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mt-3">
              <div v-text="'Ciclo dessa Etapa'" class="text-overline font-weight-bold"></div>
              <v-alert
                text
                border="left"
                color="deep-green"
                icon="calendar_month"
                class="text-caption"
              >
                <span class="font-weight-bold"> De </span> {{ item.periodo_inicial }}
                <span class="font-weight-bold"> à </span> {{ item.periodo_final }}
              </v-alert>
              <div class="d-flex justify-space-between">
                <div v-text="'Regras dessa etapa'" class="text-overline font-weight-bold"></div>
                <div class="">
                  <v-btn
                    class="mb-5"
                    x-small
                    color="primary"
                    dark
                    @click="
                      () => {
                        criarRegra(item.id);
                      }
                    "
                  >
                    Nova regra
                  </v-btn>
                </div>
              </div>
              <template>
                <div v-if="item.regras.length === 0">
                  <v-alert
                    text
                    outlined
                    border="left"
                    color="deep-orange"
                    icon="mdi-clock-fast"
                    class="text-caption"
                  >
                    Nenhuma <span class="font-weight-bold">REGRA</span> cadastrada.
                    <v-divider class="my-4 deep-orange" style="opacity: 0.22"></v-divider>
                    <v-row align="center" no-gutters>
                      <v-col class="grow">
                        Para cadastrar uma <span class="font-weight-bold">REGRA</span> basta clicar
                        em nova regra. A <span class="font-weight-bold">REGRA</span> tem como
                        finalidade criar calculos que apresente resultados e informações para o
                        preenchimento das notas lançadas nas
                        <span class="font-weight-bold">SUBETAPAS</span>.
                      </v-col>
                    </v-row>
                  </v-alert>
                </div>
                <v-card v-else class="mx-auto" max-width="100%">
                  <v-list dense color="yellow lighten-4">
                    <v-list-item-group color="orange" active-class="yellow lighten-5">
                      <template v-for="(regra, index) in item.regras">
                        <v-list-item :key="regra.descricao">
                          <template>
                            <v-list-item-content>
                              <v-list-item-title
                                v-if="regra.tipo === 'expressao'"
                                v-text="
                                  `${regra.expressao}
                                = ${
                                  getSubetapa(regra.id_subetapa_resultado)
                                    ? getSubetapa(regra.id_subetapa_resultado).descricao
                                    : 0
                                }`
                                "
                              ></v-list-item-title>
                              <div v-if="regra.tipo === 'condicao'" v-html="regra.expressao"></div>
                              <div
                                v-if="regra.tipo === 'condicao_detalhada'"
                                v-html="
                                  'Regra Avançada (visualização disponível no banco de dados)'
                                "
                              ></div>
                            </v-list-item-content>
                            <v-list-item-action>
                              <div class="d-flex">
                                <v-btn
                                  x-small
                                  class="ml-1"
                                  color="error"
                                  v-on="on"
                                  @click="() => deletarRegra(regra)"
                                >
                                  <v-icon small>fa fa-trash </v-icon>
                                </v-btn>
                              </div>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                        <v-divider v-if="index < item.regras.length - 1" :key="index"></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </template>
            </div>
            <div class="mt-3">
              <div class="d-flex justify-space-between">
                <div v-text="'Subetapas'" class="text-overline font-weight-bold"></div>
                <div class="">
                  <v-btn
                    class="mb-5"
                    color="primary"
                    dark
                    x-small
                    @click="
                      () => {
                        criarSubetapa(item);
                      }
                    "
                  >
                    Nova Subetapa
                  </v-btn>
                </div>
              </div>
              <template>
                <v-list class="py-0" dense color="yellow lighten-4">
                  <v-list-item-group color="orange" active-class="yellow lighten-5">
                    <template v-for="(sub, index) in item.subetapas">
                      <v-list-item :key="sub.descricao">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title v-text="sub.descricao"></v-list-item-title>
                            <!-- <v-list-item-subtitle class="text--primary">{{
                            item.subetapas.length
                          }}</v-list-item-subtitle> -->
                            <v-list-item-subtitle
                              v-text="`Tipo de Campo: ${verificarTipoDeCampo(sub.tipo_campo)}`"
                            ></v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text
                              v-text="'Ações*'"
                              class="font-italic"
                            ></v-list-item-action-text>

                            <v-btn-toggle dense multiple>
                              <v-btn
                                x-small
                                color="blue-grey lighten-3"
                                @click="() => editarSub(sub.id, item)"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon x-small v-bind="attrs" v-on="on">fa fa-edit </v-icon>
                                  </template>
                                  <span>Editar {{ sub.descricao }}</span>
                                </v-tooltip>
                              </v-btn>
                              <v-btn
                                x-small
                                color="error"
                                v-on="on"
                                @click="() => deletarSubEtapa(sub)"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon x-small v-bind="attrs" v-on="on">fa fa-trash </v-icon>
                                  </template>
                                  <span>Excluir {{ sub.descricao }}</span>
                                </v-tooltip>
                              </v-btn>
                            </v-btn-toggle>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                      <v-divider v-if="index < item.subetapas.length - 1" :key="index"></v-divider>
                    </template>
                    <div v-if="item.subetapas.length === 0">
                      <v-alert
                        text
                        outlined
                        border="left"
                        color="deep-orange"
                        icon="mdi-clock-fast"
                        class="text-caption"
                      >
                        Nenhuma <span class="font-weight-bold">SUBETAPA</span> cadastrada.
                        <v-divider class="my-4 deep-orange" style="opacity: 0.22"></v-divider>
                        <v-row align="center" no-gutters>
                          <v-col class="grow">
                            Para cadastrar uma <span class="font-weight-bold">SUBETAPA</span> basta
                            clicar em nova subetapa. As
                            <span class="font-weight-bold">SUBETAPAS</span> são os campos onde serão
                            inseridos ou apresentados valores
                          </v-col>
                        </v-row>
                      </v-alert>
                    </div>
                  </v-list-item-group>
                </v-list>
              </template>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- modal de regras -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogRegra" persistent max-width="600px">
          <v-card>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(submitFormRegra)"
                :disabled="submittingForm"
                ref="formRegra"
              >
                <v-card-title>
                  <span class="text-h5">Cadastrar Nova Regra</span>
                </v-card-title>
                <v-card-text>
                  <v-col class="pt-0 pb-0 mb-5" cols="12">
                    <v-divider></v-divider>
                    <h4 class="mt-3 mb-3">Selecione um Tipo de Regra</h4>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-btn
                      class="mr-5"
                      :color="tipo_regra === 'expressao' ? 'success' : ''"
                      @click="alterarTipoRegra('expressao', true)"
                      >Expressão</v-btn
                    >
                    <v-btn
                      class="mr-5"
                      :color="tipo_regra === 'condicao' ? 'success' : ''"
                      @click="alterarTipoRegra('condicao', false)"
                      >Condição</v-btn
                    >
                    <v-btn
                      :color="tipo_regra === 'condicao_detalhada' ? 'success' : ''"
                      @click="alterarTipoRegra('condicao_detalhada', false)"
                      >Condições Avançadas</v-btn
                    >
                    <div class="ma-3" v-if="tipo_regra === 'expressao'">
                      <e-label>Expressão</e-label> <br />
                      <v-subheader>Monte a sua expressao clicando nos itens abaixo</v-subheader>
                      <div class="d-flex justify-space-around">
                        <div class="mr-3" style="width: 100%">
                          <v-text-field disabled v-model="expressao" dense solo />
                        </div>
                        <div class="">
                          <v-btn depressed color="error" @click="() => limpar()"> Limpar </v-btn>
                        </div>
                      </div>
                      <e-expressao-dividers
                        type="subetapa"
                        :dados="subEtapasList"
                        label="Subetapas"
                        @printarSheet="($event) => printar($event)"
                      ></e-expressao-dividers>
                      <e-expressao-dividers
                        type="operacoes"
                        :dados="expressaoOperacoes"
                        label="Operações"
                        @printarSheet="($event) => printar($event)"
                      ></e-expressao-dividers>
                      <e-expressao-dividers
                        type="numerais"
                        :dados="expressaoNumerais"
                        label="Numerais"
                        @printarSheet="($event) => printar($event)"
                      ></e-expressao-dividers>
                    </div>
                    <div class="ma-3" v-if="tipo_regra === 'condicao'">
                      <!-- NUMERO -->
                      <template
                        v-if="tipoCondicao === 'NOTAS DO TIPO NÚMERO' && tipoCircuito === 'numero'"
                      >
                        <div class="mb-3 ml-0">
                          <e-label>Lista de Condições com notas do tipo número</e-label> <br />
                        </div>
                        <v-row
                          v-show="tipoCondicao === 'NOTAS DO TIPO NÚMERO'"
                          class="animate__animated animate__zoomIn d-flex justify-center"
                          style="animation-duration: 0.5s"
                          v-for="condicao of condicoes"
                          :key="condicoes.indexOf(condicao)"
                        >
                          <v-alert
                            text
                            border="left"
                            color="deep-green"
                            class="text-caption"
                            style="width: 100%"
                          >
                            <ValidationProvider name="SE" rules="required" v-slot="{ errors }">
                              <v-select
                                :error-messages="errors"
                                :items="subEtapasList"
                                :item-text="(subEt) => subEt.descricao"
                                :item-value="(subEt) => subEt.id"
                                label="SE"
                                v-model="condicao.se"
                                outlined
                              ></v-select>
                            </ValidationProvider>

                            <ValidationProvider name="FOR" rules="required" v-slot="{ errors }">
                              <v-select
                                :items="parametrosCondicionais"
                                :item-text="(subEt) => subEt.descricao"
                                :item-value="(subEt) => subEt.codigo"
                                :error-messages="errors"
                                v-model="condicao.for"
                                label="FOR:"
                                outlined
                              ></v-select
                            ></ValidationProvider>
                            <ValidationProvider name="QUE" rules="required" v-slot="{ errors }">
                              <v-text-field
                                :error-messages="errors"
                                type="number"
                                v-model="condicao.que"
                                outlined
                                label="Que"
                              />
                            </ValidationProvider>
                            <ValidationProvider name="ENTÃO" rules="required" v-slot="{ errors }">
                              <v-select
                                :items="subEtapasList"
                                :item-text="
                                  (subEt) => subEt.descricao + ' (' + subEt.etapa.descricao + ')'
                                "
                                :item-value="(subEt) => subEt.id"
                                :error-messages="errors"
                                v-model="condicao.entao"
                                label="Então"
                                outlined
                              />
                            </ValidationProvider>
                            <v-row>
                              <v-col class="col-8">
                                <ValidationProvider
                                  name="É IGUAL A"
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <v-text-field
                                    :error-messages="errors"
                                    v-model="condicao.e_igual"
                                    outlined
                                    label="É IGUAL A"
                                  />
                                </ValidationProvider>
                              </v-col>
                              <v-col class="col-4">
                                <ValidationProvider name="COR" v-slot="{ errors }">
                                  <v-select
                                    v-model="condicao.cor"
                                    :error-messages="errors"
                                    :items="$constants.cores"
                                    :item-text="(cor) => cor.descricao"
                                    :item-value="(cor) => cor.codigo"
                                    outlined
                                    label="Cor do Texto"
                                  />
                                </ValidationProvider>
                              </v-col>
                            </v-row>
                          </v-alert>
                        </v-row>
                      </template>

                      <!-- LETRAS -->
                      <template v-else>
                        <div class="mb-3 ml-0">
                          <e-label>Condição para notas do tipo letras</e-label> <br />
                        </div>
                        <v-row
                          class="animate__animated animate__zoomIn d-flex justify-center letras"
                          style="animation-duration: 0.5s"
                          v-for="condicao of condicoes"
                          :key="condicoes.indexOf(condicao)"
                        >
                          <v-alert
                            text
                            border="left"
                            color="deep-green"
                            class="text-caption"
                            style="width: 100%"
                          >
                            <ValidationProvider name="SE" rules="required" v-slot="{ errors }">
                              <v-select
                                :error-messages="errors"
                                :items="optionsNotaTexto"
                                :item-text="(subEt) => subEt.descricao"
                                :item-value="(subEt) => subEt.descricao"
                                label="SE A QUANTIDADE DE NOTAS DO TIPO"
                                v-model="condicao.se"
                                outlined
                              ></v-select>
                            </ValidationProvider>

                            <ValidationProvider name="FOR" rules="required" v-slot="{ errors }">
                              <v-select
                                :items="parametrosCondicionais"
                                :item-text="(subEt) => subEt.descricao"
                                :item-value="(subEt) => subEt.codigo"
                                :error-messages="errors"
                                v-model="condicao.for"
                                label="FOR:"
                                outlined
                              ></v-select
                            ></ValidationProvider>
                            <ValidationProvider name="QUE" rules="required" v-slot="{ errors }">
                              <v-text-field
                                :error-messages="errors"
                                type="number"
                                v-model="condicao.que"
                                outlined
                                label="Que"
                              />
                            </ValidationProvider>
                            <ValidationProvider name="ENTÃO" rules="required" v-slot="{ errors }">
                              <v-select
                                :items="subEtapasList"
                                :item-text="
                                  (subEt) => subEt.descricao + ' (' + subEt.etapa.descricao + ')'
                                "
                                :item-value="(subEt) => subEt.id"
                                :error-messages="errors"
                                v-model="condicao.entao"
                                label="Então"
                                outlined
                              />
                            </ValidationProvider>
                            <v-row>
                              <v-col class="col-8">
                                <ValidationProvider
                                  name="É IGUAL A"
                                  rules="required"
                                  v-slot="{ errors }"
                                >
                                  <v-text-field
                                    :error-messages="errors"
                                    v-model="condicao.e_igual"
                                    outlined
                                    label="É IGUAL A"
                                  />
                                </ValidationProvider>
                              </v-col>
                              <v-col class="col-4">
                                <ValidationProvider name="COR" rules="required" v-slot="{ errors }">
                                  <v-select
                                    v-model="condicao.cor"
                                    :error-messages="errors"
                                    :items="$constants.coresPrimarias"
                                    :item-text="(cor) => cor.descricao"
                                    :item-value="(cor) => cor.codigo"
                                    outlined
                                    label="Cor do Texto"
                                  />
                                </ValidationProvider>
                              </v-col>
                            </v-row>
                          </v-alert>
                        </v-row>
                      </template>
                      <div v-if="tipoCircuito === 'numero'" class="row">
                        <v-btn @click="adicionarCondicao" class="mx-2" fab dark color="secondary">
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div class="ma-3" v-if="tipo_regra === 'condicao_detalhada'">
                      <e-condicao-detalhada
                        @fecharCondicDetalhada="fecharCondicDetalhada()"
                        :etapaId="etapaId"
                        :subetapas="subEtapasList"
                      />
                    </div>
                  </v-col>
                  <v-col v-show="tipo_regra != 'condicao_detalhada'" class="pt-0 pb-0 mt-5">
                    <e-label
                      >Selecione o Campo para mostrar o resultado da
                      {{ tipo_regra === "expressao" ? "expressão" : "condicao" }}:
                    </e-label>
                    <ValidationProvider
                      name="Resultado da Expressao"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <e-autocomplete
                        :items="subEtapasList"
                        :return-object="false"
                        :item-text="(subEt) => subEt.descricao + ' (' + subEt.etapa.descricao + ')'"
                        :item-value="(subEt) => subEt.id"
                        :error-messages="errors"
                        v-model="id_subetapa_resultado"
                        label="Selecione uma opção"
                        dense
                        solo
                      />
                    </ValidationProvider>
                  </v-col>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="dialogRegra = false"> Cancelar </v-btn>
                  <v-btn
                    v-if="
                      tipo_regra === 'condicao' && tipoCircuito === 'letras' && btnSalvar === false
                    "
                    color="green"
                    text
                    @click="changeSelectSubetapaRegraLetras"
                  >
                    Finalizar Condições
                  </v-btn>
                  <v-btn
                    v-if="
                      tipo_regra === 'condicao' && tipoCircuito != 'letras' && btnSalvar === false
                    "
                    color="green"
                    text
                    @click="changeSelectSubetapaRegra"
                  >
                    Finalizar Condições
                  </v-btn>

                  <v-btn
                    v-if="btnSalvar === true"
                    color="green"
                    text
                    :disabled="submittingForm"
                    :loading="submittingForm"
                    type="submit"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- modal de subetapas -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogSubetapa" persistent max-width="600px">
          <v-card>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(submitFormSub)"
                :disabled="submittingForm"
                ref="formSubetapa"
              >
                <v-card-title>
                  <span class="text-h5">{{ editSub ? editSub : "Cadastrando" }} Subetapa</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="pt-0 pb-0" cols="6" xs="6" sm="6" md="6" lg="6" xg="4">
                      <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
                        <e-label>Descrição</e-label>
                        <v-text-field
                          :error-messages="errors"
                          v-model="formSub.descricao"
                          dense
                          solo
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="3" xs="3" sm="3" md="3" lg="3" xg="2">
                      <ValidationProvider name="Tipo de Campo" rules="required" v-slot="{ errors }">
                        <e-label>Tipo de Campo</e-label>
                        <e-autocomplete
                          :items="$constants.subEtapaTipoCampos"
                          :return-object="false"
                          :item-value="(value) => value.slug"
                          :item-text="(value) => value.descricao"
                          :error-messages="errors"
                          v-model="formSub.tipo_campo"
                          label="Selecione uma opção"
                          dense
                          solo
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="3" xs="3" sm="3" md="3" lg="3" xg="3">
                      <ValidationProvider name="Mostrar no Boletim" v-slot="{ errors }">
                        <v-checkbox
                          :error-messages="errors"
                          v-model="mostrarNoBoletim"
                          :label="`Mostrar No Boletim`"
                        ></v-checkbox>
                      </ValidationProvider>
                    </v-col>
                    <template v-if="formSub.tipo_campo === 'valor'">
                      <v-col class="pt-0 pb-0" cols="12" xs="12" sm="12" md="4" lg="4" xg="2">
                        <ValidationProvider name="Valor Máximo" v-slot="{ errors }">
                          <e-label>Valor Máximo (Padrão: 10,0)</e-label>
                          <v-text-field
                            v-on:change="(value) => deixarLetraMinuscula(value, 'valor_maximo')"
                            :error-messages="errors"
                            v-model="formSub.valor_maximo"
                            dense
                            solo
                            type="number"
                            step="0.2"
                          />
                        </ValidationProvider>
                      </v-col>
                    </template>

                    <v-alert text border="left" color="deep-green" class="text-caption ma-3">
                      <template>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>Valor (Inserir) - Número</v-list-item-title>
                            <v-list-item-subtitle
                              >Use para campos que precisam ser inserido valores de forma manual,
                              por exemplo (AV1, AV2) mas nao podem receber letras, somente
                              números</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>Valor (Inserir) - Texto</v-list-item-title>
                            <v-list-item-subtitle
                              >Use para campos que precisam ser inserido valores de forma manual,
                              por exemplo (AV1, AV2) mas nao podem receber números, somente
                              letras</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>Resultado (Número)</v-list-item-title>
                            <v-list-item-subtitle
                              >Use para campos que precisam calcular e apresentar o resultado sem
                              necessidade de inserir valores manuais, por exemplo: (Media
                              Bimestral)</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>Resultado (Texto)</v-list-item-title>
                            <v-list-item-subtitle
                              >Use para campos que precisam calcular e apresentar o resultado sem
                              necessidade de inserir valores manuais, por exemplo: (Resultado
                              Final)</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-alert>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="dialogSubetapa = false"> Cancelar </v-btn>
                  <v-btn
                    color="green"
                    text
                    :disabled="submittingForm"
                    :loading="submittingForm"
                    type="submit"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- modal de etapa -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogEtapa" persistent max-width="600px">
          <v-card>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
                <v-card-title>
                  <span class="text-h5">{{ editEtapa ? "Editando" : "Cadastrando" }} Etapa</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="pt-0 pb-0" cols="12">
                      <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
                        <e-label>Descrição</e-label>
                        <v-text-field
                          :error-messages="errors"
                          v-model="formEtapa.descricao"
                          dense
                          solo
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0" cols="6">
                      <e-label>Período</e-label>
                      <v-dialog
                        ref="dialogInicial"
                        v-model="modalInicio"
                        :return-value.sync="data_inicial"
                        persistent
                        width="290px"
                        :retain-focus="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider name="Período" rules="required" v-slot="{ errors }">
                            <v-text-field
                              v-model="periodoVizualicao"
                              persistent-hint
                              label="Escolha uma data"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              solo
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker v-model="data_inicial" range>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modalInicio = false"> Cancel </v-btn>
                          <v-btn text color="primary" @click="changePeriodo(data_inicial)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="3">
                      <v-checkbox
                        v-if="formEtapa.situacao_faltas >= 0"
                        v-model="formEtapa.situacao_faltas"
                        :label="`Mostrar Total de Faltas`"
                      ></v-checkbox>
                      <v-checkbox
                        v-else
                        v-model="mostrarFalta"
                        :label="`Mostrar Total de Faltas`"
                      ></v-checkbox>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="3">
                      <v-checkbox
                        v-if="formEtapa.etapa_global >= 0"
                        v-model="formEtapa.etapa_global"
                        :label="`Etapa única global`"
                      ></v-checkbox>
                      <v-checkbox
                        v-else
                        v-model="etapaGlobal"
                        :label="`Etapa única global`"
                      ></v-checkbox>
                    </v-col>
                    <v-col class="" cols="12">
                      <v-select
                        v-model="rolesArray"
                        :items="roles"
                        chips
                        label="Restringir acesso somente aos grupos"
                        multiple
                        outlined
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="dialogEtapa = false"> Cancelar </v-btn>
                  <v-btn
                    color="green"
                    text
                    :disabled="submittingForm"
                    :loading="submittingForm"
                    type="submit"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-sheet
      v-show="tipoCircuito === 'numero'"
      color="white"
      elevation="3"
      rounded
      width="100%"
      class="mt-10 pa-2"
    >
      <v-row class="ma-3">
        <h4 class="mb-3">Regras do Boletim</h4>
        <e-configuracoes-regras :circuito-id="circuitoId" />
      </v-row>
    </v-sheet>

    <v-sheet
      v-show="tipoCircuito === 'numero'"
      color="white"
      elevation="3"
      rounded
      width="100%"
      class="mt-10 pa-2"
    >
      <div class="ma-5">
        <h4 class="mb-3">Organização do boletim</h4>
        <e-layout-boletim
          :circuitoId="circuitoId"
          :etapaCriadaOuAtualizada="etapaCriada"
          :subetapaCriadaOuAtualizada="subetapaCriada"
        />
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { constants } from "buffer";
import EConfiguracoesRegras from "./EConfiguracoesRegras.vue";
import ELayoutBoletim from "./ELayoutBoletim.vue";
import EExpressaoDividers from "./EExpressaoDividers.vue";
import ECondicaoDetalhada from "./ECondicaoDetalhada.vue";
import { dateFormatParam } from "../../plugins/filters";

export default {
  components: {
    EExpressaoDividers,
    EConfiguracoesRegras,
    ECondicaoDetalhada,
    ELayoutBoletim,
  },
  name: "expansion-etapas-list",
  props: {
    circuitoId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formEtapa() {
      if (!this.etapasByCircuito || !this.editEtapa) return {};
      const etapa = this.etapasByCircuito.filter((data) => data.id === this.etapaId)[0];
      return { ...etapa };
    },
    formSub() {
      if (!this.subEtapas || !this.editSub) return {};
      const sub = this.subEtapasList.filter((data) => data.id === this.subId)[0];

      return { ...sub };
    },
    formRegra() {
      return {};
    },
  },
  mounted() {
    if (this.tipoCircuito === "letras") {
      this.tipoCondicao = "NOTAS DO TIPO LETRAS";
    } else {
      this.tipoCondicao = "NOTAS DO TIPO NÚMERO";
    }
    this.loadEtapas();
    this.loadSubEtapas();
    this.getSubEtapasListDoTipoResultado();
    this.getRoles();
  },
  data() {
    return {
      subetapaCriada: null,
      etapaCriada: null,
      etapaGlobal: 0,
      tipoCircuito: "numero",
      subEtapas: [],
      subEtapasList: [],
      etapas: [],
      etapasByCircuito: [],
      etapasListByCircuito: [],
      optionsNotaTexto: [],
      tipoCondicao: null,
      tipoCondicoes: ["NOTAS DO TIPO NÚMERO", "NOTAS DO TIPO LETRAS"],
      condicao: {
        se: null,
        for: null,
        que: null,
        entao: null,
        e_igual: null,
      },
      mostrarNoBoletim: true,
      btnSalvar: true,
      tipo_regra: "expressao",
      messageErrorPeriodo: null,
      periodos: [],
      mostrarFalta: 1,
      editingRegra: false,
      regraId: null,
      dialogSubetapa: false,
      dialogRegra: false,
      on: null,
      attrs: null,
      subEtapasListDoTipoResultado: [],
      circuito_id: this.circuitoId,
      data_inicial: null,
      data_final: null,
      modalInicio: false,
      modalFim: false,
      submittingForm: false,
      dialogEtapa: false,
      dialogSub: false,
      notifications: false,
      sound: true,
      widgets: false,
      etapaId: null,
      subId: null,
      editEtapa: null,
      editSub: null,
      id_subetapa_resultado: "",
      expressao: "",
      expressaoCodigo: "",
      camposResultadosByEtapa: this.subEtapas,
      subetapasSelecionadasForRegra: [],
      expressaoOperacoes: [
        {
          descricao: "+",
        },
        {
          descricao: "-",
        },
        {
          descricao: "*",
        },
        {
          descricao: "/",
        },
        {
          descricao: "(",
        },
        {
          descricao: ")",
        },
      ],
      expressaoNumerais: [
        {
          descricao: "1",
        },
        {
          descricao: "2",
        },
        {
          descricao: "3",
        },
        {
          descricao: "4",
        },
        {
          descricao: "5",
        },
        {
          descricao: "6",
        },
        {
          descricao: "7",
        },
        {
          descricao: "8",
        },
        {
          descricao: "9",
        },
        {
          descricao: "0",
        },
        {
          descricao: ",",
        },
      ],
      parametrosCondicionais: [
        {
          descricao: "MAIOR",
          codigo: ">",
        },
        {
          descricao: "MAIOR OU IGUAL",
          codigo: ">=",
        },
        {
          descricao: "MENOR",
          codigo: "<",
        },
        {
          descricao: "MENOR OU IGUAL",
          codigo: "<=",
        },
        {
          descricao: "IGUAL",
          codigo: "===",
        },
      ],
      editandoRegra: false,
      periodo_inicial: null,
      periodo_final: null,
      periodoVizualicao: null,
      condicoes: [{}],
      roles: [],
      rolesArray: [],
    };
  },
  methods: {
    fecharCondicDetalhada() {
      this.dialogRegra = false;
      this.loadEtapas();
    },

    async getRoles() {
      try {
        const roles = await this.$services.rolesService.syncAll();
        roles.forEach((element) => {
          this.roles.push(element.name);
        });

        return;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getEtapasByCircuitoNotaId() {
      const resp = await this.$services.etapasService.getEtapasByCircuitoNotaId(this.circuitoId);
      this.etapasByCircuito = resp;
    },

    async getSubetapasByCircuitoNotaId() {
      const resp = await this.$services.subEtapasService.syncAll(this.circuitoId);
      this.subEtapasList = resp;
    },

    changeSelectSubetapaRegra() {
      this.condicoes.forEach((element) => {
        if (!this.subetapasSelecionadasForRegra.includes(element.se)) {
          this.subetapasSelecionadasForRegra.push(element.se);
        }
      });

      const firstCond = this.condicoes[0];
      let codigo = `if(av[${firstCond.se}] ${firstCond.for} ${firstCond.que}){ cor = "${firstCond.cor}"; resultadoCondicao = "${firstCond.e_igual}"; }`;

      let codigoVisualizar = `<ul><li>Se o campo <strong>
    ${this.getSubetapa(firstCond.se)}
    </strong>  for ${firstCond.for} que ${firstCond.que} o campo <strong>${this.getSubetapa(
        this.id_subetapa_resultado
      )}</strong> será <strong style='color: ${firstCond.cor}'>${firstCond.e_igual}</strong></li>`;

      if (this.condicoes.length > 1) {
        this.condicoes.forEach((element, i) => {
          if (i > 0) {
            codigo += `else if(av[${element.se}] ${element.for} ${element.que}){ cor = "${element.cor}"; resultadoCondicao = "${element.e_igual}"; }`;
            codigoVisualizar += `<li>caso o campo  <strong>${this.getSubetapa(
              element.se
            )}</strong> seja ${element.for} que ${
              element.que
            }, logo o campo <strong>${this.getSubetapa(
              this.id_subetapa_resultado
            )}</strong> será <strong  style='color: ${element.cor} '>${
              element.e_igual
            }</strong></li>`;
          }
        });
      }
      this.expressaoCodigo = codigo;

      this.expressao = `${codigoVisualizar} </ul>`;
      this.btnSalvar = true;
    },
    changeSelectSubetapaRegraLetras() {
      this.condicoes.forEach((element) => {
        if (!this.subetapasSelecionadasForRegra.includes(element.se)) {
          this.subetapasSelecionadasForRegra.push(element.se);
        }
      });

      const firstCond = this.condicoes[0];

      const codigo = `const count = notas.reduce((acc, currentValue) => {
                    if (currentValue === '${firstCond.se}') {
                      return acc + 1;
                       } else {
                      return acc;
                      }
                    }, 0)

                    if(count ${firstCond.for} ${firstCond.que}){
                      cor = "${firstCond.cor}";
                      resultadoCondicao = "${firstCond.e_igual}";
                    }
                      `;
      this.expressaoCodigo = codigo;
      this.expressao = `Se a quantidade de notas do tipo ${firstCond.se} for ${firstCond.for} que ${firstCond.que}  o resultado será:  <strong  style='color: ${firstCond.cor} '>${firstCond.e_igual}</strong>`;

      this.btnSalvar = true;
    },
    alterarTipoRegra(tipo, btn) {
      this.tipo_regra = tipo;
      this.btnSalvar = btn;
      this.limpar();
    },

    changePeriodo(data) {
      this.modalInicio = false;
      // eslint-disable-next-line prefer-destructuring
      this.periodo_inicial = data[0];
      // eslint-disable-next-line prefer-destructuring
      this.periodo_final = data[1];
      this.periodoVizualicao = `De ${this.validarData(data[0])} à ${this.validarData(data[1])}`;
      this.messageErrorPeriodo = "Por favor, selecione uma data final";
    },
    validarData(data) {
      return dateFormatParam(data, "d/m/y");
    },
    getSubetapa(id) {
      const subInfos = this.subEtapasList.filter((d) => d.id === id);
      return subInfos[0];
    },
    verificarTipoDeCampo(tipo) {
      switch (tipo) {
        case "valor":
          return "Valor (Inserir) - Número";
        case "valor_texto":
          return "Valor (Inserir) - Texto";
        case "resultado_em_lote":
          return "Resultado (Numeros)";
        case "resultado_em_texto":
          return "Resultado (Texto)";
        default:
          return "";
      }
    },
    criarEtapa() {
      this.dialogEtapa = true;
      this.editEtapa = null;
    },
    editarEtapa(id) {
      this.editEtapa = true;
      this.etapaId = id;
      this.dialogEtapa = true;
      const etapaDados = this.getEtapa();
      this.rolesArray = JSON.parse(etapaDados.roles);
    },
    criarRegra(etapaId) {
      this.dialogRegra = true;

      this.etapaId = etapaId;

      const etapa = this.etapasByCircuito.get(parseInt(this.etapaId, 10));

      this.clearCamposRegra();
      // emitir evento
      this.loadSubEtapas();
    },
    criarSubetapa(etapa) {
      this.dialogSubetapa = true;
      this.setEtapaId(etapa.id);
    },
    editarRegra(etapaId, regraId) {
      this.editingRegra = true;
      this.regraId = regraId;
      this.dialogRegra = true;

      this.etapaId = etapaId;

      const etapa = this.etapasByCircuito.get(parseInt(this.etapaId, 10));
      const regraFilter = etapa.regras.filter((d) => d.id === regraId);
      this.loadRegra(regraFilter[0]);

      // emitir evento
      this.loadSubEtapas();
    },
    getEtapa() {
      const etapa = this.etapasByCircuito.filter((etapa) => etapa.id === this.etapaId)[0];
      return etapa;
    },
    clearCamposRegra() {
      this.expressao = "";
      this.expressaoCodigo = "";
    },
    getSubEtapasListDoTipoResultado() {
      this.subEtapasListDoTipoResultado = this.subEtapas.filter(
        (sub) => sub.tipo_campo === "resultado" || sub.tipo_campo === "resultado_em_lote"
      );
    },
    loadRegra(regra) {
      this.expressao = regra.expressao === null ? "" : regra.expressao;
      this.id_subetapa_resultado = regra.id_subetapa_resultado;
      this.expressaoCodigo = regra.expressao_codigo === null ? "" : regra.expressao_codigo;
      this.subetapasSelecionadasForRegra =
        regra.subetapas === null ? [] : regra.subetapas.split(",");
    },
    async loadEtapas() {
      try {
        this.$loaderService.open("Carregando dados");
        await Promise.all([this.getEtapasByCircuitoNotaId()]);
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async loadSubEtapas() {
      try {
        if (this.subEtapasList.length === 0) {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.getSubetapasByCircuitoNotaId()]);
          this.$loaderService.close();
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
    deixarLetraMinuscula(value, campo) {
      const novo_valor = value.toLowerCase().split(" ");
      const valor = novo_valor
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      this.form[campo] = valor;
    },
    setEtapaId(etapaId) {
      this.etapaId = etapaId;
      this.editSub = null;
      this.formSub.descricao = "";
    },
    editarSub(subId, etapa) {
      this.setEtapaId(etapa.id);
      this.subId = subId;
      this.editSub = "Editando";
      this.dialogSubetapa = true;
      const dadosSub = this.subEtapasList.filter((d) => d.id === subId);
      this.mostrarNoBoletim = dadosSub[0].mostrar_no_boletim;
    },
    async submitFormRegra() {
      this.submittingForm = true;
      try {
        this.formRegra.id = this.regraId;
        this.formRegra.etapa_id = this.etapaId;
        this.formRegra.circuito_nota_id = this.circuitoId;

        this.formRegra.expressao = this.expressao;
        this.formRegra.expressao_codigo = this.expressaoCodigo;
        this.formRegra.id_subetapa_resultado = this.id_subetapa_resultado;

        this.formRegra.subetapas = this.subetapasSelecionadasForRegra.join(",");
        const array = this.formRegra.subetapas.split(",");
        let arraySemVazios = array.filter((i) => i);

        arraySemVazios = arraySemVazios.join(",");
        this.formRegra.subetapas = arraySemVazios;

        this.formRegra.tipo = this.tipo_regra;
        if (this.editingRegra) {
          await this.$services.regrasService.atualizarRegra(this.formRegra);
        } else {
          await this.$services.regrasService.criarRegra(this.formRegra);
        }
        this.getEtapasByCircuitoNotaId();
        this.subetapasSelecionadasForRegra.length = 0;
        this.$toast.success("Etapa Atualizada com sucesso");

        this.dialogRegra = false;
        this.submittingForm = false;
        this.$refs.formRegra.reset();
        this.condicoes = [{}];
        this.tipo_regra = "expressao";
      } catch (error) {
        this.$handleError(error);
        this.submittingForm = false;
      }
    },
    async submitForm() {
      this.submittingForm = true;
      this.formEtapa.circuito_nota_id = this.circuitoId;
      this.formEtapa.periodo_inicial = this.periodo_inicial;
      this.formEtapa.periodo_final = this.periodo_final;
      this.formEtapa.roles = this.rolesArray.length > 0 ? JSON.stringify(this.rolesArray) : "[]";
      try {
        if (this.editEtapa) {
          await this.$services.etapasService.atualizarEtapa(this.formEtapa);
        } else {
          this.formEtapa.situacao_faltas = this.mostrarFalta;
          this.formEtapa.etapa_global = this.etapaGlobal;
          await this.$services.etapasService.criarEtapa(this.formEtapa);
        }

        this.etapaCriada = {
          descricao: this.formEtapa.descricao,
        };

        this.subetapasSelecionadasForRegra.length = 0;
        this.getEtapasByCircuitoNotaId();
        this.getSubetapasByCircuitoNotaId();
        this.$toast.success("Etapa Atualizada com sucesso");
        this.dialogEtapa = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async submitFormSub() {
      this.submittingForm = true;
      this.formSub.mostrar_no_boletim = this.mostrarNoBoletim;
      try {
        this.formSub.etapa_id = this.etapaId;
        this.formSub.circuito_nota_id = this.circuitoId;
        if (this.editSub) {
          await this.$services.subEtapasService.atualizarSubEtapa(this.formSub);
        } else {
          await this.$services.subEtapasService.criarSubEtapa(this.formSub);
        }

        this.subetapaCriada = {
          descricao: this.formSub.descricao,
        };

        this.getEtapasByCircuitoNotaId();
        this.getSubetapasByCircuitoNotaId();
        this.$toast.success("SubEtapa Atualizada com sucesso");
        this.dialogSubetapa = false;
        this.formSub.descricao = "";
        this.$refs.formSubetapa.reset();
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    limpar() {
      this.expressao = "";
      this.expressaoCodigo = "";
      if (this.subetapasSelecionadasForRegra) {
        this.subetapasSelecionadasForRegra.length = 0;
      }
    },
    printar(event) {
      this.editandoRegra = true;

      this.expressao += event.descricao;

      if (event.type === "subetapa") {
        this.expressaoCodigo = `${this.expressaoCodigo}av[${event.id}]`;
      } else {
        this.expressaoCodigo += event.descricao;
      }

      if (!this.subetapasSelecionadasForRegra.includes(event.id)) {
        this.subetapasSelecionadasForRegra.push(event.id);
      }
    },
    async deletarEtapa(etapa) {
      this.$modals.danger({
        title: "Cuidado!",
        message: `Tem certeza que deseja apagar esta etapa?`,
        confirmationCode: etapa.id,
        confirmationMessage: `Por favor, digite <strong>${etapa.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Etapa");
              try {
                await this.$services.etapasService.deletarEtapa(etapa);
                this.getEtapasByCircuitoNotaId();
                this.$toast.success("Etapa deletada com sucesso");
                this.etapaCriada = {
                  descricao: etapa.descricao,
                };
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async deletarSubEtapa(subetapa) {
      this.$modals.danger({
        title: "Cuidado!",
        message: `Tem certeza que deseja apagar esta subetapa?`,
        confirmationCode: subetapa.id,
        confirmationMessage: `Por favor, digite <strong>${subetapa.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Subetapa");
              try {
                await this.$services.subEtapasService.deletarSubEtapa(subetapa);
                this.getEtapasByCircuitoNotaId();
                this.getSubetapasByCircuitoNotaId();
                this.$toast.success("Subetapa deletada com sucesso");
                this.subetapaCriada = {
                  descricao: subetapa.descricao,
                };
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async deletarRegra(regra) {
      this.$modals.danger({
        title: "Cuidado!",
        message: `Tem certeza que deseja apagar esta regra?`,
        confirmationCode: regra.id,
        confirmationMessage: `Por favor, digite <strong>${regra.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Regra");
              try {
                await this.$services.regrasService.deletarRegra(regra);
                this.getEtapasByCircuitoNotaId();
                this.getSubetapasByCircuitoNotaId();
                this.$toast.success("Regra deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    adicionarCondicao() {
      const ultimaCondicao = this.condicoes[this.condicoes.length - 1];
      this.condicoes.push({ ...ultimaCondicao });
    },
    // async getCircuito() {
    //   const circuito = await this.$services.circuitoNotasService.getCircuito(this.circuitoId);
    //   this.optionsNotaTexto = circuito[0].valores_notas_texto;
    //   this.tipoCircuito = circuito[0].tipo;
    // },
  },
};
</script>
