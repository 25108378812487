import { render, staticRenderFns } from "./EModalSelectBimestresBNCC.vue?vue&type=template&id=47f98c3e&scoped=true"
import script from "./EModalSelectBimestresBNCC.vue?vue&type=script&lang=js"
export * from "./EModalSelectBimestresBNCC.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f98c3e",
  null
  
)

export default component.exports