import Axios from "@/plugins/Axios";
import Rotina from "@/Models/Rotina";

export class RotinasService {
  async syncAll({ turma_id, disciplina_id }) {
    const response = await Axios().get("rotinas", { params: { turma_id, disciplina_id } });
    const aulas = response.data.map((aula) => new Rotina(aula));
    return aulas;
  }

  async listarAulasAguardandoConfirmacao() {
    const response = await Axios().get("rotinas/listar-aulas-aguardando-confirmacao");
    const aulas = response.data.data.map((aula) => new Rotina(aula));
    return aulas;
  }

  async listarAulasAguardandoConfirmacaoPaginate(pageNumber, query) {
    const response = await Axios().get(
      `rotinas/listar-aulas-aguardando-confirmacao?page=${pageNumber}&query=${query}`
    );
    // const aulas = response.data.data.map((aula) => new Rotina(aula));
    return response.data;
  }

  async countBotoesHomer() {
    const response = await Axios().get("rotinas/count-botoes-homer");
    return response.data;
  }

  async criarAulaInfantil(aula) {
    const response = await Axios().post("rotinas/create-infantil", aula);
    return response;
  }

  async criarAula(aula) {
    const response = await Axios().post("rotinas", aula);
    return response;
  }

  async criar(aula) {
    const response = await Axios().post("rotinas", aula);
    const novaAula = new Rotina(response.data);
    return novaAula;
  }

  async getAula(aula_id) {
    const response = await Axios().get(`rotinas/get-aula/${aula_id}`);
    return new Rotina(response.data);
  }

  async buscarAulaExistente({ gestaoId, week, month, data }) {
    const response = await Axios().get(
      `rotinas/buscar-rotina-criada/${gestaoId}/${week}/${month}/${data}`
    );
    return response.data;
  }

  async atualizar(aula) {
    const response = await Axios().put(`rotinas/${aula.id}`, aula);
    const aulaAtualizada = response.data;
    return new Rotina(aulaAtualizada);
  }

  async deletar(aula) {
    const response = await Axios().delete(`rotinas/${aula.id}`);
    const aulaDeletada = response.data;
    return aulaDeletada;
  }

  async atualizarSituacaoDaAula(aula_id) {
    const response = await Axios().put(`rotinas/atualizar-situacao-da-aula/${aula_id}`);
    return response;
  }

  async atualizarSituacao(aula, novaSituacao) {
    const response = await Axios().put(`rotinas/atualizarSituacao/${aula.id}`, {
      ...aula,
      situacao: novaSituacao,
    });
    const aulaAtualizada = response.data;
    return new Rotina(aulaAtualizada);
  }

  async recuperar(aula) {
    return this.atualizarAula({ ...aula, deleted_at: null });
  }

  async listarAulasEmConflitos(aula_id) {
    const response = await Axios().get(`rotinas/listar-aulas-conflitos/${aula_id}`);
    return response;
  }

  async pesquisarAulasEmConflitos(data, horario_id, turma_id, id_aula = null) {
    const response = await Axios().get(
      `rotinas/verificar-aula-existente/${data}/${parseInt(horario_id, 10)}/${parseInt(
        turma_id,
        10
      )}/${parseInt(id_aula, 10)}`
    );
    return response;
  }
}
export default new RotinasService();
