<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => this.$router.go(-1)"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            >PLANO DE DESENVOLVIMENTO PSICOEDUCACIONAL INDIVIDUALIZADO (PDPI) COORDENAÇÃO DE
            EDUCAÇÃO ESPECIAL</v-card-title
          >
          <v-card-text>
            <h4>Turma: {{ gestao ? gestao.turma.descricao : "- - -" }}</h4>
            <h4>Aluno: {{ gestao ? gestao.matricula.aluno.nomecompleto : "- - -" }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div>
              Professor(a): {{ gestao ? gestao.professor.nome : "- - -" }} | Tipo de Ensino:
              {{ gestao ? gestao.disciplinas_vinculo[0].descricao : "" }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row class="mt-5">
        <v-select
          :items="bimestres"
          item-text="descricao"
          item-value="id"
          v-model="bimestreSelecionado"
          return-object
          label="Selecione um bimestre"
          solo
        ></v-select>
      </v-row>
    </v-card-text>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row v-for="(item, index) in $constants.fichaEducacaoEspecialMensal" :key="index">
          <v-col cols="12">
            <ValidationProvider :name="item.descricao" rules="required" v-slot="{ errors }">
              <e-label>{{ item.descricao }}</e-label>
              <v-textarea solo dense v-model="form[item.form]" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  watch: {
    bimestreSelecionado() {
      this.loadBasicData();
    },
  },
  data() {
    return {
      submittingForm: false,
      gestao: null,
      form: {},
      painel: null,
      question:
        "RELATE AS PRINCIPAIS INFORMAÇÕES REFERENTES AO DESENVOLVIMENTO MOTOR, SOCIOEMOCIONAL E COGNITIVO DO ALUNO (A) NESTE ANO LETIVO, BEM COMO SUA EXPERIÊNCIA DE TRABALHO COM A FAMÍLIA, COM OS DEMAIS PROFESSORES E COM A GESTÃO ESCOLAR.",
      matricula: null,
      gestao_de_aulas: [],
      bimestres: [
        { id: 0, descricao: "1 Bimestre" },
        { id: 1, descricao: "2 Bimestre" },
        { id: 2, descricao: "3 Bimestre" },
        { id: 3, descricao: "4 Bimestre" },
      ],
      bimestreSelecionado: {
        id: 0,
      },
    };
  },
  methods: {
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestao_id } = this.$route.params;
        this.form.gestao_id = gestao_id;
        this.form.mes_id = this.bimestreSelecionado.id;
        this.$toast.info("Relatório salvo com sucesso!");
        await this.$services.educacaoInclusivaService.lancarFichaDeDesenvolvimentoBimestral(
          this.form
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$router.push({ name: "gestoesDeAulasAuxiliares" });
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestao_id } = this.$route.params;
        const response =
          await this.$services.educacaoInclusivaService.carregarrFichaDeDesenvolvimentoBimestral(
            gestao_id,
            this.bimestreSelecionado.id
          );
        this.form = response.ficha ? response.ficha : {};
        this.lancarRelatorio =
          this.$constants.FICHA_EDUCACAO_DESENVOLVIMENTO_ESPECIAL_FUNDAMENTAL_FINAIS;
        this.matricula = response.matricula;
        this.gestao = response.gestao;
        this.gestao_de_aulas = response.gestao_de_aulas;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
