<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Turmas </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarTurma" class="mr-2">Criar Turma</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="turmasList"
          :loading="carregandoTurmas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                :class="parseInt(turma.notifiq, 10) === 1 ? 'green lighten-5' : ''"
                v-for="turma of items"
                :key="turma.id"
              >
                <td>{{ turma.id }}</td>
                <td>{{ turma.codigo }}</td>
                <td>
                  <v-btn text @click="() => visualizarTurma(turma)">
                    {{ turma.descricao }}
                  </v-btn>
                  <v-chip
                    v-if="parseInt(turma.notifiq, 10) === 1"
                    label
                    v-bind="attrs"
                    v-on="on"
                    class="white--text"
                    color="green darken-4"
                    x-small
                  >
                    NOTIFIQ
                  </v-chip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip label v-bind="attrs" v-on="on" color="primary" x-small> S </v-chip>
                    </template>
                    <span
                      v-html="
                        `Séries: ${turma.series.length} unidades <br>` +
                        turma.series.map((serie) => serie.descricao).join('<br />')
                      "
                    ></span>
                  </v-tooltip>
                  <v-tooltip v-if="!turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        label
                        v-bind="attrs"
                        v-on="on"
                        color="secondary"
                        x-small
                        v-if="turma.multi"
                        class="ml-1"
                      >
                        M
                      </v-chip>
                    </template>
                    <span>DISCIPLINA MULTI</span>
                  </v-tooltip>
                </td>
                <td class="text-center">{{ turma.matriculas_count }}/{{ turma.qtd }}</td>
                <!-- <td>{{ turma.serie ? turma.serie.descricao : "- - -" }}</td> -->
                <!-- <td>{{ turma.contexto }}</td> -->
                <td>
                  <v-tooltip v-if="!turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => dashboard(turma)"
                      >
                        <v-icon small>mdi-chart-box </v-icon>
                      </v-btn>
                    </template>
                    <span>Métricas da turma</span>
                  </v-tooltip>
                  <v-tooltip v-if="!turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarTurma(turma)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar a turma</span>
                  </v-tooltip>

                  <v-tooltip v-if="!turma.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarTurma(turma)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar turma</span>
                  </v-tooltip>

                  <v-tooltip v-if="turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarTurma(turma)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar turma</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          @changeActionResponse="listarTurmas"
          :search="search"
          :pageNumber="parseInt(pageNumber)"
        >
        </e-paginate>
      </v-col>
    </v-row>
    <e-selecionar-gestao-modal
      :dialog="dialog"
      :turma_id="turma_id"
      @dialogChange="dialog = $event"
    ></e-selecionar-gestao-modal>
  </main-template>
</template>

<script>
// import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";
import Turma from "@/Models/Turma";

export default {
  computed: {
    // ...mapGetters("Turmas", ["turmasList", "carregandoTurmas"]),
  },
  data() {
    return {
      carregandoTurmas: false,
      dialog: false,
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      turmasList: [],
      turma_id: null,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código", value: "codigo" },
          { text: "Nome", value: "descricao" },
          { text: "Opções", value: "multi", sortable: false },
          { text: "Quantidade", value: "quantidade", sortable: false },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.listarTurmas(this.pageNumber);
  },
  watch: {
    carregandoTurmas(value) {
      if (value && this.turmasList.length === 0) {
        this.$loaderService.open("Carregando turmas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 3) {
        await this.listarTurmas(0, val);
      }
      if (!val) {
        await this.listarTurmas(0);
      }
    },
  },
  methods: {
    async listarTurmas(pageNumber, query = null) {
      this.carregandoTurmas = true;
      this.$loaderService.open("Carregando Turmas");
      try {
        const response = await this.$services.turmasService.syncAllPaginate(pageNumber, query);
        this.turmasList = response.data.map((turma) => new Turma(turma));
        this.paginate = response;
      } catch (error) {
        this.$loaderService.close();
        this.$handleError(error);
      }
      this.carregandoTurmas = false;
      this.$loaderService.close();
    },
    criarTurma() {
      this.$router.push({ name: "turmas.create" });
    },
    visualizarTurma(turma) {
      if (turma.isInfantil) {
        // || parseInt(turma.curso_id, 10) === 3
        this.turma_id = turma.id;
        this.dialog = !this.dialog;
        return;
      }
      this.$router.push({ name: "turmas.view", params: { turma_id: turma.id } });
    },
    editarTurma(turma) {
      this.$router.push({ name: "turmas.edit", params: { turma_id: turma.id } });
      // return turma;
    },
    dashboard(turma) {
      this.$router.push({ name: "turmas.dashboard", params: { id: turma.id } });
      // return turma;
    },
    async deletarTurma(turma) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta turma?",
        confirmationCode: turma.id,
        confirmationMessage: `Por favor, digite <strong>${turma.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Turma");
              try {
                await this.$services.turmasService.deletarTurma(turma);
                this.$toast.success("Turma deletada com sucesso");
                await this.listarTurmas();
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarTurma(turma) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta turma?",
        confirmationCode: turma.id,
        confirmationMessage: `Por favor, digite <strong>${turma.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Turma");
              try {
                await this.$services.turmasService.recuperarTurma(turma);
                this.$toast.success("Turma recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
