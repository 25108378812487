<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Selecione os campos</v-card-title>

        <v-card-text>
          <v-col class="d-flex" cols="12" sm="12">
            <v-select
              :items="$constants.meses"
              item-text="descricao"
              item-value="id"
              v-model="selectMonth"
              return-object
              label="Selecione um mês"
              solo
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="12">
            <v-select
              :items="semanasValidas"
              item-text="name"
              item-value="id"
              v-model="selectWeek"
              return-object
              label="Selecione n° da semana"
              solo
              :disabled="semanasValidas.length === 0"
            ></v-select>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text" @click="$emit('dialogChange', false)">Fechar</v-btn>
          <v-btn
            color="primary"
            text
            @click="emitSelectedValues"
            :disabled="semanasValidas.length === 0"
            >Gerar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    gestao_id: {
      type: [Number, String],
      required: true,
    },
    rotina_create_url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectMonth: null,
      selectWeek: null,
      semanasValidas: [],
    };
  },
  watch: {
    selectMonth() {
      this.updateSemanasValidas();
    },
  },
  methods: {
    emitSelectedValues() {
      if (this.selectMonth && this.selectWeek) {
        this.$emit("selectedValues", {
          week: this.selectWeek.name,
          month: this.selectMonth.descricao,
        });
        this.rotinaCreate();
      }
    },
    updateSemanasValidas() {
      if (this.selectMonth) {
        // Implemente aqui a lógica para determinar as semanas válidas para o mês selecionado
        // Exemplo básico: contar quantas semanas existem no mês selecionado
        const semanasValidas = this.calcularSemanasValidas(this.selectMonth.id);

        // Atualizar o estado com as semanas válidas
        this.semanasValidas = semanasValidas.map((semana) => ({
          id: semana.id,
          name: `${semana.id}ª semana`,
        }));

        // Resetar a seleção da semana
        this.selectWeek = null;
      } else {
        this.semanasValidas = [];
      }
    },
    calcularSemanasValidas(mesId) {
      const semanasDoMes = [];

      // Obtém o primeiro e último dia do mês
      const primeiroDia = new Date(new Date().getFullYear(), mesId - 1, 1);
      const ultimoDia = new Date(new Date().getFullYear(), mesId, 0);

      let inicioSemana = new Date(primeiroDia);
      const fimSemana = new Date(primeiroDia);

      // Itera pelos dias do mês
      while (fimSemana <= ultimoDia) {
        // Verifica se fimSemana é um dia útil (de segunda a sexta)
        if (fimSemana.getDay() >= 1 && fimSemana.getDay() <= 5) {
          // Se fimSemana é sexta-feira, adiciona a semana ao array semanasDoMes
          if (fimSemana.getDay() === 5) {
            semanasDoMes.push({
              id: semanasDoMes.length + 1,
              inicio: new Date(inicioSemana),
              fim: new Date(fimSemana),
            });
            inicioSemana = new Date(fimSemana);
          }
        }

        fimSemana.setDate(fimSemana.getDate() + 1);
      }

      return semanasDoMes;
    },
    async rotinaCreate() {
      try {
        this.$loaderService.open("Carregando dados...");

        const response = await this.$services.rotinasService.buscarAulaExistente({
          gestaoId: this.gestao_id,
          week: this.selectWeek.name,
          month: this.selectMonth.descricao,
        });

        if (response) {
          const routeUrl = this.$router.resolve({
            name: "rotinasInfantis.edit",
            params: {
              gestaoDeAulaId: response.instrutorDisciplinaTurma_id,
              aula_id: response.id,
            },
          }).href;
          this.$router.push(routeUrl);
        } else {
          const routeUrl = this.$router.resolve({
            name: "rotinasInfantis.create",
            params: {
              gestaoDeAulaId: this.gestao_id,
              week: this.selectWeek.name,
              month: this.selectMonth.descricao,
            },
          }).href;
          this.$router.push(routeUrl);
        }
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
  },
};
</script>
