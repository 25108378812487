<template>
  <span class="font-weight-bold">{{ formattedValue }}</span>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
  },
  computed: {
    formattedValue() {
      if (this.valueIsEmpty) {
        return "- - -";
      }
      const parsedValue = parseFloat(this.value);
      const firstDecimal = (parsedValue * 10) % 10; // Obtém a primeira casa decimal multiplicando por 10 e pegando o módulo 10
      console.log(firstDecimal);
      let roundedValue;

      if (firstDecimal <= 1) {
        roundedValue = Math.floor(parsedValue * 10) / 10; // Arredonda para baixo para uma casa decimal
      } else {
        roundedValue = Math.round(parsedValue * 10) / 10; // Arredonda para o número mais próximo com uma casa decimal
      }

      return roundedValue.toFixed(1); // Formata para exibir uma casa decimal
    },

    valueIsEmpty() {
      return this.value === null || this.value === undefined || isNaN(this.value);
    },
  },
};
</script>
