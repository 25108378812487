import NewCreateRotinaInfantil from "@/Pages/RotinasInfantis/NewCreateRotinaInfantil.vue";
import IndexRotinaInfantil from "@/Pages/RotinasInfantis/IndexRotinaInfantil.vue";

export default [
  /* Rotinas Infantis */
  {
    path: "/gestoes-de-rotinas-infantil/:gestaoDeAulaId/aulas/create/:week/:month",
    name: "rotinasInfantis.create",
    component: NewCreateRotinaInfantil,
    props: true,
  },
  {
    path: "/gestoes-de-rotinas-infantil/:gestaoDeAulaId/aulas",
    name: "rotinasInfantis",
    component: IndexRotinaInfantil,
    props: true,
  },
  {
    path: "/gestoes-de-rotinas-infantil/:gestaoDeAulaId/aulas/edit/:aula_id",
    name: "rotinasInfantis.edit",
    component: NewCreateRotinaInfantil,
    props: { editing: true },
  },
];
