<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> PLANO MENSAL </v-card-title>

        <v-select
          :items="$constants.mesesPlanoMensal"
          item-text="descricao"
          item-value="id"
          v-model="mes"
          return-object
          label="Selecione um mês"
          solo
        ></v-select>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :dialog="dialog"
            @status="dialog = $event"
            color="blue darken-1"
            text
            @click="cancelar()"
            >Cancelar</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :disabled="isDisabled"
            @click="gerarPlanoMensal(gestaoDeAulasId, mes?.id, disciplinaId)"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    gestaoDeAulasId: {
      type: Number,
    },
    disciplinaId: {
      type: Number,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    typeModel: {
      type: String,
    },
  },

  watch: {
    dialog(val) {
      this.$emit("status", val);
    },
    typeModel(val) {
      this.type = val;
    },
  },
  data() {
    return {
      disciplina_id: null,
      disciplinasDaGestao: [],
      type: null,
      mes: null,
    };
  },
  computed: {
    isDisabled() {
      return this.disciplinaSelecionada || !this.mes;
    },
  },
  methods: {
    async gerarPlanoMensal(gestaoDeAulasId, mes, disciplinaId) {
      try {
        this.$loaderService.open("Gerando plano mensal...");
        await this.$services.relatoriosService.planoMensalInfantil(
          parseInt(gestaoDeAulasId, 10),
          parseInt(mes, 10),
          parseInt(disciplinaId, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    cancelar() {
      this.disciplinaSelecionada = null;
      this.mes = null;
      this.$emit("status", false);
    },
  },
};
</script>
