<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1>
              <v-btn color="primary" x-small text @click="goBack">
                <v-icon left> fa fa-angle-left </v-icon>
              </v-btn>
              Rotinas
            </h1>
          </v-col>
        </v-row>
        <v-card v-if="gestaoDeAula">
          <v-row>
            <v-col cols="4">
              <v-card-text>
                <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
                <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
                <h4>
                  Carga horária:
                  {{
                    gestaoDeAula.carga_horaria
                      ? gestaoDeAula.carga_horaria
                      : "[ SEM CARGA HORÁRIA ]"
                  }}
                </h4>
                <h4>Professor: {{ gestaoDeAula.professor.nome }}</h4>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-col cols="12" class="pt-3 px-0 mt-3">
          <div class="d-flex justify-space-between">
            <div>
              <v-btn color="primary" @click="openModal" class="mr-2">Criar Rotina Infantil</v-btn>
            </div>
            <v-text-field
              v-model="search"
              class="ml-2 search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="aulasList"
          :loading="carregandoAulas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="aula.estaDeletado ? ['deleted'] : []"
                v-for="aula in items"
                :key="aula.id"
              >
                <td>{{ aula.id }}</td>
                <td>{{ aula.mes }}</td>
                <td>{{ aula.data_formatada }}</td>
                <td>{{ aula.numero_da_semana }}</td>
                <td class="text-right">
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verMotivoAula(aula)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar rotina </span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => gerarRotinaPDF(aula)"
                      >
                        <v-icon small>fa-file-text </v-icon>
                      </v-btn>
                    </template>
                    <span>Gerar PDF</span>
                  </v-tooltip>
                  <!-- <v-tooltip v-if="!aula.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          aula.descricaoSituacao !== 'Aguardando confirmação'
                            ? !$can(['aulas.atualizarAposDefinirSituacao'])
                            : false
                        "
                        :to="{
                          name: 'aulas.frequencia',
                          params: { gestaoDeAulaId, aula_id: aula.id },
                        }"
                      >
                        <v-icon small>fa fa-thumbs-up </v-icon>
                      </v-btn>
                    </template>
                    <span>Lançar Frequência</span>
                  </v-tooltip> -->
                  <v-tooltip v-if="!aula.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          aula.descricaoSituacao !== 'Aguardando confirmação' &&
                          aula.descricaoSituacao !== 'Aula em conflito'
                            ? true
                            : false
                        "
                        @click="() => editarAula(aula)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar rotina </span>
                  </v-tooltip>
                  <v-tooltip v-if="!aula.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          aula.descricaoSituacao !== 'Aguardando confirmação' &&
                          aula.descricaoSituacao !== 'Aula em conflito'
                            ? true
                            : false
                        "
                        @click="() => deletarAula(aula)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar rotina </span>
                  </v-tooltip>
                  <v-tooltip v-if="aula.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarAula(aula)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar Aula </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>

      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <v-alert
            class="mt-2"
            style="text-align: center"
            type="orange"
            v-if="aulasConflitadasEncontradas"
          >
            Procurando aulas em conflitos
          </v-alert>

          <h2 class="mt-4">Aulas conflitadas</h2>
          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Turma</th>
                  <th class="text-left">Disciplina</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-if="aulasConflitadas.length <= 0">
                  <td>SEM AULAS EM CONFLITOS</td>
                </tr>
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor.nome }}
                    <br />
                    <v-chip color="grey" dark x-small>
                      {{ item.turma.franquia.descricao }}
                    </v-chip>
                  </td>
                  <td>{{ item.situacao }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.turma.descricao }}</td>
                  <td>{{ item.disciplina.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </v-row>
    <e-drawer-motivo-rotina v-model="verMotivo" :aula="aula"> </e-drawer-motivo-rotina>
    <e-drawer-historico-aulas v-model="openDrawer" :gestao_id="parseInt(gestaoDeAulaId, 10)">
    </e-drawer-historico-aulas>
    <!-- Dialog -->
    <e-modal-select-month-week-rotina
      :dialog="dialog"
      :gestao_id="gestaoDeAulaId"
      :rotina_create_url="rotinaCreateUrl"
      @selectedValues="handleSelectedValues"
      @dialogChange="dialog = $event"
    ></e-modal-select-month-week-rotina>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  props: {
    gestaoDeAulaId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      aulasConflitadasEncontradas: false,
      aulasConflitadas: [],
      aulasEmConflitos: false,
      dataTableFilter,
      aulasList: [],
      carregandoAulas: false,
      relatorio: [],
      gestaoDeAulas: null,
      verMotivo: false,
      dialog: false,
      openDrawer: false,
      tipo_diario: 0,
      aula: {},
      gestaoDeAula: null,
      gestaoDeAulaIds: this.$route.params.gestaoDeAulaId || null,
      rotinaCreateUrl: "",
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Mês", value: "mes" },
          { text: "Data", value: "data_formatada" },
          { text: "N° da semana", value: "situacao" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
            width: "300px",
          },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    goBack() {
      this.$router.push({ name: "gestoesDeAulas" });
    },
    openModal() {
      this.rotinaCreateUrl = this.$router.resolve({
        name: "rotinasInfantis.create",
        params: { gestaoDeAulaIds: this.gestaoDeAulaId },
      }).href;
      this.dialog = true;
    },
    handleSelectedValues(values) {
      this.numeroSemanaSelecionada = values.week;
      this.mesSelecionado = values.month;
    },
    async diarioNotas() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.diarioNotas(this.gestaoDeAulaId);
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async gerarRotinaPDF(aula) {
      try {
        this.$loaderService.open("Gerando rotina...");
        await this.$services.relatoriosService.gerarRotinaPDF(parseInt(aula.id, 10));
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    verMotivoAula(aula) {
      this.verMotivo = !this.verMotivo;
      this.aula = aula;
    },
    editarAula(aula) {
      this.$router.push({
        name: "rotinasInfantis.edit",
        params: { aula_id: aula.id },
      });
    },
    alterarSituacao(Objeto) {
      this.$set(this.aulasList, Objeto.i, Objeto.aulaAtualizada);
      this.loadData();
    },
    async verificaSituacao(aula_id) {
      this.aulasEmConflitos = !this.aulasEmConflitos;
      const data = await this.$services.aulasService.listarAulasEmConflitos(aula_id);
      this.aulasConflitadas = data.data;
      if (data.data.length === 0) {
        this.$services.aulasService.atualizarSituacaoDaAula(aula_id).then(() => {
          // this.$router.go();
          this.$forceUpdate();
          window.location.reload();
        });
      }
    },
    async loadData() {
      this.carregandoAulas = true;
      this.$loaderService.open("Carregando Rotinas");
      try {
        const response = await this.$services.gestoesDeAulasService.listarRotinasGestaoInfantil(
          this.gestaoDeAulaId
        );
        this.aulasList = response.rotinas;
        this.gestaoDeAula = response.payload.gestaoDeAula;
        this.relatorio = response.payload.relatorio;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
      this.carregandoAulas = false;
    },
    async deletarAula(aula) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta rotina?",
        confirmationCode: aula.id,
        confirmationMessage: `Por favor, digite <strong>${aula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Rotina");
              try {
                await this.$services.rotinasService.deletar(aula);
                this.aulasList = this.aulasList.filter((item) => item.id !== aula.id);
                this.$toast.success("Rotina deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
    recuperarAula(aula) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta aula?",
        confirmationCode: aula.id,
        confirmationMessage: `Por favor, digite <strong>${aula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Aula");
              try {
                await this.$services.aulasService.recuperarAula(aula);
                this.$toast.success("Aula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async verHistoricoAulas() {
      this.openDrawer = !this.openDrawer;
    },
  },
};
</script>

<style></style>
